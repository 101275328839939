<template>
  <v-app>
<BreadcrumbComponent :items=items></BreadcrumbComponent>
<v-container v-if=enable>
<v-card>
    <v-tabs
      class="tabs"
      centered
      grow
      height="60px"
      v-model="activeTab"
    >
      <v-tab v-for="tab in tabs" :key="tab.id" exact>
        {{ tab.name }}
      </v-tab>

      <v-tab-item key="1">
        <ProfileView :profile=profile :business=business></ProfileView>
      </v-tab-item>
      <v-tab-item key="2">
        <StoresView :stores=stores :business=business></StoresView>
      </v-tab-item>
      <v-tab-item key="3">
        <DevicesView :devices=devices :stores=stores :business=business></DevicesView>
      </v-tab-item>
      <v-tab-item key="4">
        <RenewalsView :devices=devices :stores=stores :business=business :renewals=renewals></RenewalsView>
      </v-tab-item>
    </v-tabs>
  </v-card>
</v-container>
<PreloaderComponent :enable=enable></PreloaderComponent>
</v-app>
</template>

<script>
import BreadcrumbComponent from '../../components/BreadcrumbComponent.vue'
import PreloaderComponent from '../../components/PreloaderComponent.vue'
import ProfileView from './ProfileView.vue'
import StoresView from './StoresView.vue'
import DevicesView from './DevicesView.vue'
import RenewalsView from './RenewalsView.vue'

export default {
  name: 'AccountDetailsView',
  methods: {
    init: function(){
          this.tabs =  [
          { id: 1, name: "Profil"},
          { id: 2, name: 'Magasins'},
          { id: 3, name: 'Appareils'},
          { id: 4, name: 'Renouvellements'},
        ]
    },
    updateDeviceInfo: function(data){
      for (var i = this.devices.length - 1; i >= 0; i--) {
       if(this.devices[i].id == data.id){
          this.devices[i] = data
       }
      }
    },
    load: function(){
      var self = this;
      this.$axios.get("/businesses/"+this.$route.params.id+"?include=stores,devices,renewals")
      .then(function (response) {
        self.business = response.data
        self.profile = [
          {label: "NOM ENTREPRISE", name: response.data.name, key:"name"},
          {label: "TELEPHONE", name: response.data.phone, key:"phone"},
          {label: "E-MAIL", name: response.data.email, key:"email"},
          {label: "ADRESSE", name: response.data.address, key:"address"},
          {label: "NOM CONTACT", name: response.data.contact_full_name, key:"contact_full_name"},
          {label: "TELEPHONE CONTACT", name: response.data.contact_phone, key:"contact_phone"},
          {label: "E-MAIL CONTACT", name: response.data.contact_email, key:"contact_email"},
          {label: "IDENTIFICATION", name: response.data.contact_id, key:"contact_id"},
          {label: "TYPE IDENTIFICATION", name: response.data.contact_id_type, key:"contact_id_type"}
        ]
        self.stores = response.data.stores
        self.renewals = response.data.renewals
        self.devices = response.data.devices
        self.items =  [
        {
          text: 'COMPTES',
          disabled: false,
          href: '/accounts',
        },
        {
          text: 'PROFIL',
          disabled: false,
          href: '#',
        },
        {
          text: response.data.name,
          disabled: false,
          href: '#',
        }
      ]
        self.enable = true          
      })
    },
  },
  mounted(){
    this.init()
    this.load()
  },
  data: () => ({
      business: [],
      account:{id:0},
      id: 0,
      rates:[],
      enable:false,
      profile: [],
      renewals: [],
      stores: [],
      devices: [],
      items: [
        {
          text: 'COMPTES',
          disabled: false,
          href: '/accounts',
        },
        {
          text: 'PROFIL',
          disabled: false,
          href: '#',
        }
      ],
      tab: null,
      activeTab: 0,
      tabs: [
          
        ],
    }),
  components: { BreadcrumbComponent, PreloaderComponent, ProfileView, StoresView, DevicesView, RenewalsView },
}
</script>

<style type="text/css">
  .v-slide-group__wrapper{
        background: #fff;
    color: black;
    border-bottom: 2px solid #ddd;
  }
  .v-tab--active{
    background:#f2f2f2;
  }
  .v-tab{
    color:black!important;
  }
  .v-tabs-slider-wrapper{
    display:none;
  }
  a{
    color:black!important;
  }

  .v-date-picker-table .v-application .v-btn{
    width:32px!important;
  }
</style>