<template>
  <v-card>
  <v-row class="floatrow">
   <v-dialog
     v-model="dialog"
     scrollable
     max-width="600px"
   >
   <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
          class="new_button"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ form_title }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
          <v-row>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-text-field
                  label="Nom *"
                  required
                  v-model=device.name
                ></v-text-field>
                
              </v-col>
              <v-col cols="12" sm=6 md=6>
                <v-text-field
                  label="Fournisseur *"
                  required
                  v-model=device.manufacturer
                ></v-text-field>
              </v-col>
              <v-col cols="6" sm=6 md=6>
                <v-text-field
                  label="Modèle *"
                  required
                  v-model=device.model
                ></v-text-field>
              </v-col>
              <v-col cols="6" sm=6 md=6>
                <v-text-field
                  label="Numéro de Serie"
                  required 
                  v-model=device.serial_number
                ></v-text-field>
              </v-col>
              <v-col cols="6" sm=6 md=6>
                <v-text-field
                  label="Mensualité"
                  required 
                  v-model=device.amount
                ></v-text-field>
              </v-col>
              <v-col
                cols="6"
                sm="6"
              >
                <v-select
                  :items="stores"
                  item-value=id
                  item-text=name
                  label="Magasin *"
                  required
                  v-model=device.store_id
                ></v-select>
              </v-col>
              <v-col
                cols="6"
                sm="6"
              >
                <v-select
                  :items="status"
                  item-value=id
                  item-text=name
                  label="Statut *"
                  required
                  v-model=device.status
                ></v-select>
              </v-col>
              <v-col
      cols="12"
      sm="6"
      md="6"
    >
      <v-dialog
        ref="dialog"
        v-model="modal"
        :return-value.sync="device.next_renewal_date"
        persistent
        width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="device.next_renewal_date"
            label="Prochain Renouvellement"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="device.next_renewal_date"
          scrollable
        >
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="modal = false"
          >
            Annuler
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="$refs.dialog.save(device.next_renewal_date)"
          >
            OK
          </v-btn>
        </v-date-picker>
      </v-dialog>
    </v-col>
          </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            class="close_modal_button"
            text
            @click="close"
          >
            Fermer
          </v-btn>
          <v-btn
            color="blue"
            text
            class = "confirm_modal_button"
            v-on:click=save
            :loading="loading"
            :disabled="loading"
            @click="loader = 'loading'"
          >
            Valider
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
        class="tablesearch"
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="devices"
      :search="search"
    >
    <template v-slot:item.store_id="{ item }">
        {{ getStoreLabel(item.store_id) }}
    </template>
    <template v-slot:item.amount="{ item }">
        {{ item.amount | formatNumber }}
    </template>
    <template v-slot:item.status="{ item }">
      <v-chip
        :class="getStatusColor(item.status)"
      >
        {{ getStatusLabel(item.status) }}
      </v-chip>
    </template>
      <template v-slot:item.actions="{ item }">
    <v-icon
        small
        class="mr-2 edit_button"
        @click="edit(item)"
      >
        mdi-pencil
      </v-icon>
    </template>
    </v-data-table>
  </v-card>
</template>

<script>
  import store from '@/store/index.js';

  export default {
    props: {
      devices: [], 
      stores: [],
      business: []
    },
    methods: {
      edit: function(item){
        this.index = this.devices.indexOf(item)
        this.device = Object.assign({}, item)
        this.form_title = "Editer Appareil : "+this.device.name
        this.dialog = true
      },
      close: function(){
        this.dialog = false
        this.reset()
        this.loading = false
      },
      getStatusColor: function(status){
        var color = "class_red"
        if(status){
          color = "class_green"
        }
        return color
      },
      getStoreLabel: function(item){
        var label = ""
        for (var i = this.stores.length - 1; i >= 0; i--) {
          if(this.stores[i].id == item){
            label = this.stores[i].name
          }
        }
        return label
      },
      getStatusLabel: function(status){
        var label = "Bloqué"
        if(status){
          label = "Actif"
        }
        return label
      },
      getAmount: function(amount){
        return "$"+amount
      },
      validateData: function(){
        if(this.device.name == ""){
          alert("Vous devez un nom pour l'appareil")
          return false  
        }

        if(this.device.manufacturer == ""){
          alert("Vous devez indiquer un fournisseur")
          return false
        }

        if(this.device.model == ""){
          alert("Vous devez indiquer le modèle de l'appareil")
          return false
        }

        if(this.device.serial_number == ""){
          alert("Vous devez indiquer le numéro de série de l'appareil")
          return false
        }

        if(this.device.amount == ""){
          alert("Vous devez indiquer le montant de la souscription")
          return false
        }

        return true;
      },
      save: function(){
        var self = this
        if(this.validateData()){
          this.loading = true
          var url = ''
          if (this.index > -1) { 
            url = '/devices/'+this.device.id
            this.$axios.put(url+"?data="+encodeURIComponent(JSON.stringify(self.device)))
            .then(response => {
              Object.assign(self.devices[self.index], response.data)
              self.dialog = false
              self.loading = false
              self.reset()
            })
          }else{
            url = '/devices'
            this.device.business_id = this.business.id
            this.device.user_id = parseInt(store.getters.StateUser.id)
            this.$axios.post(url+"?data="+encodeURIComponent(JSON.stringify(self.device)))
            .then(response => {
              self.devices.push(response.data);
              self.dialog = false
              self.loading = false
              self.reset()
            }).catch(function(error){
              console.log(error)
            })
          }
        }
      },
      reset: function(){
        this.device = {
          name: '', 
          manufacturer: '', 
          model: '', 
          serial_number: '', 
          business_id: '', 
          store_id: 0, 
          status: 1,
          amount:6000,
          next_renewal_date:(new Date(Date.now())).toISOString().substr(0, 10),
        }
        this.index = -1
        this.form_title = 'Nouvel Appareil'
      }
    },
    data () {
      return {
        search: '',
        menu: false,
        modal: false,
        menu2: false,
        loading: false,
        status: [{name: "Actif", id: true}, {name: 'Bloqué', id: false}],
        dialog:'',
        index: -1,
        form_title: 'Nouvel Appareil',
        device: {
          name: '', 
          manufacturer: '', 
          model: '', 
          serial_number: '', 
          business_id: '', 
          store_id: 0, 
          status: 1,
          amount:6000,
          next_renewal_date:(new Date(Date.now())).toISOString().substr(0, 10),
        },
        headers: [
          {
            text: 'NOM',
            align: 'start',
            sortable: false,
            value: 'name',
          },
          { text: 'FOURNISSEUR', value: 'manufacturer' },
          { text: 'MODELE', value: 'model' },
          { text: 'SN', value: 'serial_number' },
          { text: 'MENSUALITE', value: 'amount' },
          { text: 'PROCHAIN RENOUVELLEMENT', value: 'next_renewal_date' },
          { text: 'STATUT', value: 'status' },
          { text: 'ACTIONS', value: 'actions' },
        ],
    }
  }
}
</script>
<style type="text/css">
	.tablesearch .v-input__slot:before{
    display:none;
  }
  .floatrow{
  text-align: right;
   float: right;
   margin-right: 21px;
   margin-left: 5px;
  }
.new_button{
    width:50px;
    margin-top:46px!important;
    float:right;
  }

  .tablesearch .v-input__control{
    border: 1px solid #ddd;
    padding: 5px 10px;
  }
  .edit_button{
    font-size: 16px;
    padding: 5px;
    background: #003f74 !important;
    color: white!important;
    border-radius: 4px;
  }

  .view_button{
    font-size: 16px;
    padding: 5px;
    background: #fb8c00 !important;
    color: white!important;
    border-radius: 4px;
  }
  td.text-start:last-child, th.text-start:last-child{
    text-align:right!important;
  }
  .v-dialog .v-card__title{
    background:#f3f3f3;
    border-bottom:1px solid #ddd;
  }
  .class_green{
   background:#4caf50 !important;
   color:white!important;
  }
  .class_red{
   background:#ff5252 !important;
   color:white!important;
  }
  .v-btn.close_modal_button{
    background-color:#1976d2!important;
    color:white!important;
  }
  .v-btn.confirm_modal_button{
    background-color:#4caf50!important;
    color:white!important;
  }

  .v-dialog .v-card__actions{
    padding-bottom: 15px!important;
    padding-top: 15px!important;
    background: #f3f3f3;
    border-top: 1px solid #ddd;
  }
  .tablesearch .v-input__slot:before{
    display:none;
  }
  .v-card__actions .v-progress-circular{
    margin-top:1px !important;
  }
    .v-card__actions .v-progress-circular circle{
      color:white!important;
    }
</style>