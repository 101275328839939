<template>
	<v-simple-table>
    <template v-slot:default>
      <tbody>
        <tr
          v-for="item in profile"
          :key="item.name"
        >
          <th>{{ item.label }}</th>
          <td class="text-right">{{ item.name }}</td>
        </tr>
        <tr>
          <th>ACTIONS</th>
          <td class="text-right">
            <v-icon
        small
        class="edit_button"
        @click="edit(business)"
      >
        mdi-pencil
      </v-icon>
          </td>
        </tr>
      </tbody>
      <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ form_title }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-text-field
                  label="Nom Entreprise *"
                  required
                  v-model=account.name
                ></v-text-field>
                
              </v-col>
              <v-col cols="12" sm=6 md=6>
                <v-text-field
                  label="Téléphone Entreprise *"
                  required
                  v-model=account.phone
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm=6 md=6>
                <v-text-field
                  label="E-mail *"
                  required
                  v-model=account.email
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm=12 md=12>
                <v-text-field
                  label="Adresse Entreprise"
                  required 
                  v-model=account.address
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-text-field
                  label="Nom Contact"
                  required
                  v-model=account.contact_full_name
                ></v-text-field>
                
              </v-col>
              <v-col cols="12" sm=6 md=6>
                <v-text-field
                  label="Téléphone"
                  required
                  v-model=account.contact_phone
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm=6 md=6>
                <v-text-field
                  label="E-mail Contact"
                  required
                  v-model=account.contact_email
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm=6 md=6>
                <v-text-field
                  label="Numéro d'Itentification"
                  required
                  v-model=account.contact_id
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm=6 md=6>
                <v-select
                  :items="id_types"
                  item-value=name
                  item-text=name
                  label="Type d'Identification *"
                  required
                  v-model=account.contact_id_type
                ></v-select>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-text-field
                  label="Prefix (Sous-domaine) *"
                  required
                  v-model=account.prefix
                ></v-text-field>
                
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            class="close_modal_button"
            text
            @click="close"
          >
            Fermer
          </v-btn>
          <v-btn
            color="blue"
            text
            class = "confirm_modal_button"
            v-on:click=save
            :loading="loading"
            :disabled="loading"
            @click="loader = 'loading'"
          >
            Valider
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    </template>
  </v-simple-table>
</template>

<script>
export default {
  name: 'ProfileView',
  props: {
    profile: {},
    business: []
  },
  methods: {
    save: function(){
      var self = this;
      if(this.validateData()){
        this.loading = true
        var url = '/businesses/'+this.account.id
        this.$axios.put(url+"?data="+encodeURIComponent(JSON.stringify(self.account)))
          .then(response => {
            self.business = response.data
            self.profile = [
            {label: "NOM ENTREPRISE", name: response.data.name, key:"name"},
            {label: "TELEPHONE", name: response.data.phone, key:"phone"},
            {label: "E-MAIL", name: response.data.email, key:"email"},
            {label: "ADRESSE", name: response.data.address, key:"address"},
            {label: "NOM CONTACT", name: response.data.contact_full_name, key:"contact_full_name"},
            {label: "TELEPHONE CONTACT", name: response.data.contact_phone, key:"contact_phone"},
            {label: "E-MAIL CONTACT", name: response.data.contact_email, key:"contact_email"},
            {label: "IDENTIFICATION", name: response.data.contact_id, key:"contact_id"},
            {label: "TYPE IDENTIFICATION", name: response.data.contact_id_type, key:"contact_id_type"},
            {label: "SOUS-DOMAINE", name: response.data.prefix, key:"prefix"}
            ]
            self.dialog = false
            self.loading = false
            self.reset()
          })
        }
      },
      validateData: function(){
        if(!/^[a-z0-9]*$/.test(this.account.prefix)){
          alert("Le préfix ne peut contenir que des chiffres et des lettres minuscules")
          return false
        }
        if(this.account.name == ""){
          alert("Vous devez un nom pour le compte")
          return false  
        }

        if(this.account.address == ""){
          alert("Vous devez indiquer une adresse")
          return false
        }

        if(this.account.prefix == ""){
          alert("Vous devez choisir un prefix. Seuls les lettres et les chiffres sont autorisés.")
          return false
        }

        if(this.account.phone == ""){
          alert("Vous devez choisir un téléphone")
          return false
        }

        if(this.account.email == ""){
          alert("Vous devez choisir un email")
          return false
        }

        if(this.account.contact_full_name == ""){
          alert("Vous devez indiquer un nom pour la personne de contact")
          return false
        }

        if(this.account.contact_phone == ""){
          alert("Vous devez indiquer un téléphone pour la personne de contact")
          return false
        }

        if(this.account.contact_email == ""){
          alert("Vous devez indiquer un email pour la personne de contact")
          return false
        }

        if(this.account.contact_id == ""){
          alert("Vous devez indiquer un numéro d'identité pour la personne de contact")
          return false
        }
        return true;
      },
      edit: function(item){
        this.account = Object.assign({}, item)
        this.form_title = "Editer Compte : "+this.account.name
        this.dialog = true
      },
      close: function(){
        this.dialog = false
        this.loading = false
      }
  },
  data () {
      return {
        loading: false,
        id_types: [{name:'CIN'}, {name:'NIF'}, {name:'PASSPORT_NUMBER'}, {name:'DRIVER_LICENSE'}],
        dialog:'',
        form_title: '',
        account: {
          name: '', 
          address: '', 
          prefix:'',
          phone: '', 
          email: '', 
          contact_full_name: '', 
          contact_phone: '', 
          contact_email: '',
          contact_id:'',
          contact_id_type:"CIN"
        }
    }
  }
}
</script>
