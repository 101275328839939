<template>
  <v-card>
  <v-row class="floatrow">
   <v-dialog
     v-model="dialog"
     max-width="600px"
   >

      <v-card>
        <v-card-title>
          <span class="text-h5">{{ form_title }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
          <v-row>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-text-field
                  label="Nom *"
                  required
                  v-model=store.name
                ></v-text-field>
                
              </v-col>
              <v-col cols="12" sm=6 md=6>
                <v-text-field
                  label="Téléphone *"
                  required
                  v-model=store.phone
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm=6 md=6>
                <v-text-field
                  label="E-mail *"
                  required
                  v-model=store.email
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm=12 md=12>
                <v-text-field
                  label="Adresse"
                  required 
                  v-model=store.address
                ></v-text-field>
              </v-col>
          </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            class="close_modal_button"
            text
            @click="close"
          >
            Fermer
          </v-btn>
          <v-btn
            color="blue"
            text
            class = "confirm_modal_button"
            v-on:click=save
            :loading="loading"
            :disabled="loading"
            @click="loader = 'loading'"
          >
            Valider
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Rechercher"
        single-line
        hide-details
        class="tablesearch"
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="stores"
      :search="search"
    >
    <template v-slot:item.rate_id="{ item }">
    {{ getRateLabel(item.rate_id) }}
    
    </template>
      <template v-slot:item.actions="{ item }">
    <v-icon
        small
        class="mr-2 edit_button"
        @click="edit(item)"
      >
        mdi-pencil
      </v-icon>
    </template>
    </v-data-table>
  </v-card>
</template>

<script>
	import store from '@/store/index.js';
  export default {
    props: {
      stores: [],
      business: [],
    },
    methods: {
      edit: function(item){
        this.index = this.stores.indexOf(item)
        this.store = Object.assign({}, item)
        this.form_title = "Editer Magasin : "+this.store.name
        this.dialog = true
      },
      getRateLabel: function(rate_id){
        if(rate_id == 1){
          return "HTG"
        }else{
          return 'USD'
        }
      },
      close: function(){
        this.dialog = false
        this.reset()
        this.loading = false
      },
      validateData: function(){
        if(this.store.name == ""){
          alert("Vous devez un nom pour le magasin")
          return false  
        }

        if(this.store.phone == ""){
          alert("Vous devez indiquer une téléphone")
          return false
        }

        if(this.store.email == ""){
          alert("Vous devez choisir un email")
          return false
        }

        if(this.store.address == ""){
          alert("Vous devez choisir une adresse")
          return false
        }

        return true;
      },
      save: function(){
        var self = this;
        if(this.validateData()){
          this.loading = true
          var url = ''
          if (this.index > -1) { 
            url = '/stores/'+this.store.id
            this.$axios.put(url+"?data="+encodeURIComponent(JSON.stringify(self.store)))
            .then(response => {
              Object.assign(self.stores[self.index], response.data)
              self.dialog = false
              self.loading = false
              self.reset()
            })
          }else{
            url = '/stores'
            this.store.user_id = parseInt(store.getters.StateUser.id)
            this.store.business_id = this.business.id
            this.$axios.post(url+"?data="+encodeURIComponent(JSON.stringify(self.store)))
            .then(response => {
              self.stores.push(response.data);
              self.dialog = false
              self.loading = false
              self.reset()
            })
          }
        }
      },
      reset: function(){
        this.store = {
          name: '', 
          address: '', 
          phone: '', 
          email: ''
        }
        this.index = -1
        this.form_title = 'Nouveau MAgasin'
      }
    },
    data () {
      return {
        search: '',
        loading: false,
        dialog:'',
        index: -1,
        form_title: 'Nouveau Magasin',
        store: {
          name: '', 
          address: '', 
          phone: '', 
          email: ''
        },
        headers: [
          {
            text: 'NOM',
            align: 'start',
            sortable: false,
            value: 'name',
          },
          { text: 'TELEPHONE', value: 'phone' },
          { text: 'E-MAIL', value: 'email' },
          { text: 'ADRESSE', value: 'address' },
          { text: 'DEVISE DE VENTE', value: 'rate_id' },
          { text: 'ACTIONS', value: 'actions' },
        ],
    }
  }
}
</script>
<style type="text/css">
	.tablesearch .v-input__slot:before{
    display:none;
  }
 .floatrow{
  text-align: right;
   float: right;
   margin-right: 21px;
   margin-left: 5px;
  }
  .tablesearch .v-input__control{
    border: 1px solid #ddd;
    padding: 5px 10px;
  }
  .edit_button{
    font-size: 16px;
    padding: 5px;
    background: #003f74 !important;
    color: white!important;
    border-radius: 4px;
  }

  .new_button{
    width:50px;
    margin-top:44px!important;
    float:right;
    border-radius:1px;
    height:44px;
  }

  .view_button{
    font-size: 16px;
    padding: 5px;
    background: #fb8c00 !important;
    color: white!important;
    border-radius: 4px;
  }
  td.text-start:last-child, th.text-start:last-child{
    text-align:right!important;
  }
  .v-dialog .v-card__title{
    background:#f3f3f3;
    border-bottom:1px solid #ddd;
  }
  .v-btn.close_modal_button{
    background-color:#1976d2!important;
    color:white!important;
  }
  .v-btn.confirm_modal_button{
    background-color:#4caf50!important;
    color:white!important;
  }

  .v-dialog .v-card__actions{
    padding-bottom: 15px!important;
    padding-top: 15px!important;
    background: #f3f3f3;
    border-top: 1px solid #ddd;
  }
  .v-card__actions .v-progress-circular{
    margin-top:1px !important;
  }
    .v-card__actions .v-progress-circular circle{
      color:white!important;
    }
</style>